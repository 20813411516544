<template >
  <v-row class="d-flex flex-column align-center" title>
    <v-col v-show="is_chat" cols="12" md="8">
      <v-card style="position: relative; height: 450px" class="">
        <div
          v-if="channel"
          class="px-4 py-2 primary white--text"
          style="position: absolute; top: 0; width: 100%"
        >
          <div class="d-flex justify-space-between align-center">
            <h3>{{ channel.name }}</h3>
            <small class="grey--text text--lighten-3">{{
              channel.createdAt | formatDateNow
            }}</small>
          </div>
          <div class="">
            <p class="body-2">
              Participantes: {{ channel.created_user.first_name }},
              {{  channel.reciver_user ? channel.reciver_user.first_name : 'Buscando Asesor' }}
            </p>
          </div>
        </div>
        <div ref="chat" class="chat__body px-4" style="padding: 110px 0">
          <Loading v-if="isLoading" />
          <div
            v-else
            class="d-flex justify-center flex-column"
            v-for="(m, index) in messages"
            :key="index"
          >
            <!-- left -->
            <div
              v-if="getUser.id === m.user.id"
              class="
                align-self-end
                body-1
                rounded-lg
                lighten-3
                px-2
                py-2
                primary
                mb-4
              "
            >
              <p class="mb-0">
                <span>{{ m.message }}</span>
                <small class="caption d-block pt-1 text-right grey--text">{{
                  m.createdAt | formatDateNow
                }}</small>
              </p>
            </div>

            <!-- rigth -->
            <div
              v-else
              class="
                grey
                body-1
                rounded-lg
                lighten-3
                px-2
                py-2
                mb-4
                align-self-start
              "
            >
              <p class="mb-0">
                <span class="">{{ m.message }}</span>
                <small class="caption d-block pt-1 text-right grey--text">{{
                  m.createdAt | formatDateNow
                }}</small>
              </p>
            </div>
          </div>
        </div>
        <v-card-actions
          class="d-block"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: white;
          "
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                @keydown.enter="save"
                filled
                v-model.trim="message"
                hide-details
                append-icon="mdi-send"
                rounded
                style="width: 100%"
                clear-icon="d-block"
                label="Escribe un mensaje"
                @click:append="save"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { formatDateNow } from "../../filters/moment";

import Loading from "../../components/dashboard/Loading.vue";
export default {
  components: { Loading },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Chat",
      };
    },
  },
  name: "message",
  beforeRouteLeave(to, from, next) {
    clearInterval(this.refreshIntervalId);
    next();
  },
  async mounted() {
    this.isLoading = true;
    await this.inicialChat();
    this.isLoading = false;
    this.refreshIntervalId = setInterval(this.initialize, 10000);
  },
  data() {
    return {
      is_chat: false,
      refreshIntervalId: null,
      uuidDelete: 0,
      editedIndex: -1,
      editedItem: {
        user_id: null,
      },
      defaultItem: {
        user_id: null,
      },
      messages: [],
      channel: null,
      message: "",
      isLoading: false,
    };
  },
  filters: {
    formatDateNow,
  },
  computed: {
    ...mapGetters("message", ["getErrors"]),
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("auth", ["getUserAdminRandom"]),
    ...mapActions("message", ["createMessage", "setErrors"]),
    ...mapActions("channel", [
      "createChannel",
      "getChannel",
      "getMessagesByChannel",
      "updateNumMessages",
      "setErrors",
    ]),

    async initialize() {
      if (this.channel) {
        this.messages = await this.getMessagesByChannel(this.channel.id);
        this.setErrors("clear");
      }
    },

    deleteItem(item) {
      this.uuidDelete = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteEvent(this.uuidDelete);
      if (this.getErrors.length == 0) {
        this.closeDelete();
        this.initialize();
      }
    },

    async validateForm() {
      if (this.message.length <= 0) {
        return false;
      }
      return true;
    },
    autoScroll() {
      var chat = this.$refs.chat;
      chat.scrollTop = chat.scrollHeight - 140;
      // console.log(chat.scrollHeight);
    },

    async save() {
      if (await this.validateForm()) {
        if (!this.editedItem.id) {
          const data = {
            message: this.message,
            chat_channel_id: this.channel.id,
            sender_id: this.getUser.id,
          };
          // console.log(data);
          await this.createMessage(data);
          await this.updateNumMessages({ id: this.channel.id, reset: false });

          if (this.getErrors.length == 0) {
            this.initialize();
            this.message = "";
            this.autoScroll();
          }
        } else {
          await this.updateEvent({
            id: this.editedItem.id,
            item: {
              message: message,
              date_end: this.editedItem.date_end,
            },
          });
          if (this.getErrors.length == 0) {
            this.initialize();
            // this.selectedEvent = this.editItem;
          }
        }
      }
    },

    async inicialChat() {
      // obtener un admin random
      const admin_random = await this.getUserAdminRandom();
      const data = {
        created_user_id: this.getUser.id,
      };
      const channelStorage = await this.getChannel(this.getUser.id);

      let channel;
      if (!channelStorage) {
        // crear canal
        const resp = await this.createChannel(data);
        // obtener canal
        channel = await this.getChannel(this.getUser.id);

      } else {
        // obtener canal
        channel = await this.getChannel(this.getUser.id);
      }
      this.channel = channel;
      // get messages
      await this.initialize();
      this.is_chat = true;
    },
    closeChat() {
      this.is_chat = false;
      sessionStorage.removeItem("channelStorage");
    },
  },
};
</script>

<style scoped>
.chat__body {
  height: 100% !important;
  overflow-y: auto;
}
</style>
